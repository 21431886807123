import React from 'react'

const GamesTop: React.FC <Params> = (params) => {
  return (
    <div className = 'top'>
      <h1>What we have here</h1>
    </div>
  )
}

interface Params {}

export default GamesTop
